import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import menuIcon from "../assets/images/menu.png";
import logo from "../assets/images/logoCoolG.png";
import axios from "axios";

function Navbar() {
  const backendUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isServiceAlive, setIsServiceAlive] = useState(false);

  useEffect(() => {
    const checkHeartbeat = async () => {
      try {
        const response = await axios.get(`${backendUrl}/workers/heartbeat`);
        setIsServiceAlive(response.data.workers.alive === true);
      } catch (error) {
        setIsServiceAlive(false);
      }
    };

    // Check service status every 5 seconds
    const intervalId = setInterval(checkHeartbeat, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [backendUrl]);

  useEffect(() => {
    const saveUserData = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();
          console.log("User data:", user);
          const response = await axios.post(
            `${backendUrl}/createUser`,
            {
              email: user.email,
              name: user.name,
              password: token,
            },
            { headers: { Authorization: `Bearer ${token}` } },
          );
          console.log(response.data);
        } catch (error) {
          if (error.response && error.response.status === 409) {
            console.log("User already exists in the database.");
          } else {
            console.error("Error saving user data", error);
          }
        }
      }
    };
    saveUserData();
  }, [isAuthenticated, user, getAccessTokenSilently, backendUrl]);

  const goToWallet = () => {
    navigate("/wallet", {
      state: {
        email: "",
        username: "",
        password: "",
      },
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="bg-gray-100 bg-opacity-80 m-5 p-5 shadow-lg rounded-2xl align-center justify-center">
      <div className="flex justify-between items-center">
        {/* Logo and Service Status */}
        <div className="flex items-center space-x-4">
          <Link to="/">
            <img src={logo} alt="CoolGoat" className="h-12 w-16" />
          </Link>
          <div className="flex items-center space-x-2">
            <span className="font-semibold">Workers Status:</span>
            <div
              className={`w-4 h-4 rounded-full ${isServiceAlive ? "bg-green-500" : "bg-red-500"}`}
            />
          </div>
        </div>

        {/* Authentication Buttons / Menu */}
        <div className="relative">
          {isAuthenticated ? (
            <button
              onClick={toggleDropdown}
              className="flex items-center space-x-2 bg-blue-700 text-white px-4 py-2 rounded-full hover:bg-blue-800 transition"
            >
              <img src={menuIcon} alt="Menu" className="h-6 w-6" />
              <span>Menu</span>
            </button>
          ) : (
            <div className="flex space-x-4">
              <button
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: { screen_hint: "signup" },
                  })
                }
                className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition"
              >
                Registrarse
              </button>
              <button
                onClick={loginWithRedirect}
                className="bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition"
              >
                Iniciar Sesión
              </button>
            </div>
          )}

          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg"
              onMouseLeave={() => setIsDropdownOpen(false)} // Cerrar menú al salir
            >
              <button
                onClick={goToWallet}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              >
                Billetera
              </button>
              <Link to="/fixtures">
                <button className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100">
                  Fixtures
                </button>
              </Link>
              <Link to="/recommendations">
                <button className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100">
                  Recomendaciones
                </button>
              </Link>
              <button
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
