import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function PaymentConfirmation() {
  const location = useLocation();
  const [estado, setEstado] = useState("Verificando...");
  // const [isVerified, setIsVerified] = useState(false);
  const token_ws = new URLSearchParams(location.search).get("token_ws");
  const uuid = localStorage.getItem("uuid");

  console.log("token_ws", token_ws);
  console.log("uuid", uuid);

  useEffect(() => {
    const verifyPayment = async () => {
      if (token_ws) {
        try {
          const response = await axios.post(
            `${API_URL}/transbank/verify`,
            {
              token_ws: token_ws,
              uuid: uuid,
            },
            { headers: { Authorization: `Bearer ${token_ws}` } },
          );

          setEstado(response.data.message || "Verificación completada");
          // setIsVerified(true);
          const valid = response.data.status === "success";

          const velidation = await axios.post(
            `${API_URL}/buyBet/webpayValidation`,
            {
              valid: valid,
              uuid: uuid,
            },
            { headers: { Authorization: `Bearer ${token_ws}` } },
          );
          console.log("Validation", velidation);
        } catch (error) {
          setEstado("Error en la verificación");
          console.error("Error confirmando la transacción:", error);
        }
      }
    };
    verifyPayment();
  }, [token_ws]);

  return (
    <div className="bg-white p-8 rounded-lg shadow-lg text-center">
      <h1 className="text-2xl font-bold uppercase mb-4">Resultado del Pago</h1>
      <p className="text-lg uppercase">{estado}</p>
    </div>
  );
}

export default PaymentConfirmation;
