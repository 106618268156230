import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_URL;

export default function WalletPage() {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const [cash, setCash] = useState("");
  const [message, setMessage] = useState("");
  const [backendUser, setBackendUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleAddCash = async (e) => {
    e.preventDefault();
    if (!cash || isNaN(cash) || parseFloat(cash) <= 0) {
      setMessage("Por favor, ingresa una cantidad válida");
      return;
    }
    try {
      // Obtener el token JWT usando Auth0
      const token = await getAccessTokenSilently();

      // Hacer la solicitud al backend para añadir dinero
      const response = await axios.post(
        `${API_URL}/addCash/${backendUser.id}`,
        { cash: parseFloat(cash) },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      setMessage(response.data.message || "Dinero añadido con éxito");

      // Recargar los datos del usuario para mostrar el saldo actualizado
      await fetchUserData();
    } catch (error) {
      setMessage(error.response?.data?.message || "Error al añadir dinero");
    }
  };

  const fetchUserData = async () => {
    setLoading(true); // Iniciar la carga
    if (isAuthenticated && user) {
      try {
        // Obtener el token JWT de Auth0
        const token = await getAccessTokenSilently();

        // Hacer la solicitud al backend para obtener los datos del usuario
        const response = await axios.get(
          `${API_URL}/getInfo/userByEmail/${user.email}`,
          { headers: { Authorization: `Bearer ${token}` } },
        ); // Obtener el usuario por email
        console.log(response.data);

        // Almacenar los datos del usuario obtenidos del backend
        setBackendUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Finalizar la carga
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [isAuthenticated, user]);

  if (loading) {
    return (
      <div className="flex justify-center min-h-screen text-gray-100">
        <h2 className="text-2xl font-bold mb-4">Cargando...</h2>
      </div>
    );
  }
  return (
    <div className="container mx-auto p-8 max-w-md bg-gray-100 bg-opacity-80 rounded-lg shadow-lg">
      {isAuthenticated ? (
        <div>
          <h2 className="text-center text-3xl font-bold mb-6 text-gray-800">
            Wallet <span className="text-blue-700">{backendUser.name}</span>
          </h2>
          <div className="bg-blue-100 p-4 rounded-lg mb-4 shadow-sm">
            <p className="text-xl font-semibold text-gray-700">
              Saldo:{" "}
              <span className="text-green-600">
                ${backendUser.wallet.balance}
              </span>
            </p>
            <p className="text-lg text-gray-600 mt-2">
              <strong>Total ganado:</strong>{" "}
              <span className="text-green-500">
                ${backendUser.wallet.total_amount_won}
              </span>
            </p>
            <p className="text-lg text-gray-600 mt-1">
              <strong>Apuestas ganadas:</strong>{" "}
              <span className="text-green-500">
                {backendUser.wallet.bets_won_count}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Wallet</h2>
      )}

      <form
        onSubmit={handleAddCash}
        className="bg-gray-50 p-6 rounded-lg shadow-sm"
      >
        <input
          type="number"
          value={cash}
          onChange={(e) => setCash(e.target.value)}
          placeholder="Cantidad a añadir"
          className="border border-gray-300 p-3 rounded w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          required
        />
        <button
          type="submit"
          className="w-full bg-blue-700 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Añadir Dinero
        </button>
      </form>

      {message && <p className="mt-4 text-red-700 text-center">{message}</p>}
    </div>
  );
}
