import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import ProjectRoutes from "./Routes";
import "./assets/css/App.css";
import fondo from "./assets/images/fondo2.jpg";

function App() {
  return (
    <Router>
      <div
        className="flex flex-col h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${fondo})` }}
      >
        <Navbar />
        <div className="flex-grow overflow-y-auto p-4 no-scrollbar">
          <ProjectRoutes />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
