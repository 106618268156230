import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

export default function Register() {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, user } =
    useAuth0();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Manejar el registro o login con Auth0
  const handleRegister = async (e) => {
    e.preventDefault();

    // Redirigir al usuario al flujo de login o registro de Auth0
    loginWithRedirect({
      authorizationParams: { screen_hint: "signup" },
      connection: "Username-Password-Authentication", // Método estándar
      redirectUri: `${window.location.origin}/callback`,
    });
  };

  const checkIfUserExists = async (email) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(
        `${API_URL}/getInfo/userByEmail/${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return response.data.exists; // Asume que el endpoint devuelve "exists: true/false"
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Si el usuario no existe, devolver false
        return false;
      } else {
        console.error("Error comprobando si el usuario existe:", error);
        throw error;
      }
    }
  };

  // Enviar el email y nombre a la API local después de la autenticación
  const saveUserDataToApi = async () => {
    if (isAuthenticated && user) {
      setIsLoading(true);
      try {
        const userExists = await checkIfUserExists(user.email);
        const token = await getAccessTokenSilently();
        // Enviar el email y nombre a tu API local
        if (!userExists) {
          // Registrar el usuario si no existe en la API local
          const response = await axios.post(
            `${API_URL}/createUser`,
            {
              email: user.email,
              username: user.name,
              password: token,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );

          console.log("Usuario guardado en la API local:", response.data);
        } else {
          console.log("El usuario ya está registrado en la API local.");
        }

        navigate("/fixtures");
      } catch (error) {
        console.error("Error guardando los datos del usuario:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Ejecutar el guardado de datos en la API local cuando el usuario esté autenticado
  useEffect(() => {
    if (isAuthenticated) {
      saveUserDataToApi();
    }
  }, [isAuthenticated, user]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center">
      <div className="bg-white bg-opacity-90 p-8 rounded-2xl shadow-xl w-full max-w-md">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-4">
          Bienvenid@
        </h1>
        <h4 className="text-xl text-center text-gray-700 mb-6">
          Regístrate o inicia sesión usando Auth0 o Google
        </h4>
        <button
          onClick={handleRegister}
          className="w-full py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full focus:outline-none focus:shadow-outline"
        >
          Registrarse/Iniciar sesión
        </button>
        {isLoading && <p>Cargando...</p>}
      </div>
    </div>
  );
}
