import React from "react";

function Footer() {
  return (
    <footer className="footer p-1 bg-gray-100 bg-opacity-10 text-gray-800">
      <div className="flex justify-center items-center text-gray-900">
        <p>Grupo5 - Arquitectura de Software - 2024-2</p>
      </div>
    </footer>
  );
}

export default Footer;
