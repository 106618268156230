import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

export default function Callback() {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const saveUserDataToApi = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently();

          let userExists;
          try {
            userExists = await axios.get(
              `${API_URL}/getInfo/userByEmail/${user.email}`,
              { headers: { Authorization: `Bearer ${token}` } },
            );
            console.log(userExists.data);
          } catch (error) {
            if (error.response && error.response.status === 404) {
              userExists = { data: { exists: false } };
            } else {
              throw error;
            }
          }

          if (!userExists.data.exists) {
            await axios.post(
              `${API_URL}/createUser`,
              {
                email: user.email,
                username: user.name,
                password: token, // Use token if necessary
              },
              { headers: { Authorization: `Bearer ${token}` } },
            );
            console.log("Usuario creado");
          }

          // After saving, redirect to the fixtures page
          navigate("/fixtures");
        } catch (error) {
          console.error("Error guardando los datos del usuario:", error);
          navigate("/");
        }
      }
    };

    saveUserDataToApi();
  }, [isAuthenticated, user, getAccessTokenSilently, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <p>Cargando...</p>
    </div>
  );
}
