import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_URL;

function BetForm() {
  const location = useLocation();
  const { fixtureId, odds } = location.state || {}; // Get fixtureId and odds from state
  const [amount, setAmount] = useState("");
  const [betType, setBetType] = useState("Home"); // Default bet type
  const [message, setMessage] = useState(null); // To display messages to the user
  // const [bets, setBets] = useState([]); // To display bets placed in that fixture
  const [loading, setLoading] = useState(false);
  const [backendUser, setBackendUser] = useState(null);
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const fetchUserData = async () => {
    setLoading(true); // Iniciar la carga
    if (isAuthenticated && user) {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_URL}/getInfo/userByEmail/${user.email}`,
          { headers: { Authorization: `Bearer ${token}` } },
        ); // Obtener el usuario por email
        console.log(`getInfo/userByEmail/\n
          ${response.data}`);

        // Almacenar los datos del usuario obtenidos del backend
        setBackendUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Finalizar la carga
      }
    }
  };

  const fetchBets = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${API_URL}/getInfo/bets/${fixtureId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      // setBets(response.data.rows);
    } catch (error) {
      console.error("Error fetching bets:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        fetchBets(token);
        fetchUserData(token);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [isAuthenticated, user]);

  const handlePlaceBet = async () => {
    try {
      console.log(amount, betType, fixtureId);
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${API_URL}/buyBet/${backendUser.id}/${fixtureId}`,
        {
          cantidad: amount,
          apuesta: betType,
          esWallet: true,
          token: " ",
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      setMessage(response.data.message);
      fetchBets();
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    } catch (error) {
      setMessage(error.response?.data?.message || "Error al ingresar apuesta");
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    }
  };

  const handlePay = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/transbank/token`,
        {
          amount: amount,
          sessionId: "sesion-24",
          returnUrl: `${process.env.REACT_APP_URL}/paymentConfirmation`, // Colocarl pagina a la que se redirecciona. Para confirmar compra
        },
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      const data = response.data;

      localStorage.setItem("uuid", data.uuid);
      console.log("uuid-1", localStorage.getItem("uuid"));

      if (data) {
        const responseBroker = await axios.post(
          `${API_URL}/buyBet/${backendUser.id}/${fixtureId}`,
          {
            cantidad: amount,
            apuesta: betType,
            esWallet: false,
            token: data.token,
          },
          {
            headers: { "Content-Type": "application/json" },
          },
        );

        console.log(responseBroker);

        if (responseBroker.status === 200) {
          const form = document.createElement("form");
          form.method = "POST";
          form.action = data.url;

          const hiddenTokenField = document.createElement("input");
          hiddenTokenField.type = "hidden";
          hiddenTokenField.name = "token_ws";
          hiddenTokenField.value = data.token;

          form.appendChild(hiddenTokenField);
          document.body.appendChild(form);
          form.submit();
        }
      }
    } catch (error) {
      console.error("Error iniciando la compra:", error);
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-8">
        <h2 className="text-2xl font-bold mb-4">Cargando...</h2>
      </div>
    );
  }

  return (
    <div className="border border-gray-300 p-6 rounded-lg shadow-lg bg-gray-100 bg-opacity-80 max-w-lg mx-auto">
      <h3 className="text-xl font-bold text-gray-800 mb-6 text-center">
        Elige tu Apuesta
      </h3>
      <div className="mb-4">
        <label
          htmlFor="amount"
          className="block text-sm font-medium text-gray-600 mb-1"
        >
          N° de Bonos
        </label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2"
          placeholder="Ingresa cantidad de bonos a comprar"
        />
      </div>

      <div className="mb-6">
        <label
          htmlFor="betType"
          className="block text-sm font-medium text-gray-600 mb-1"
        >
          Opción
        </label>
        <select
          id="betType"
          value={betType}
          onChange={(e) => setBetType(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 "
        >
          <option value="home">Home (x{odds[0].odd})</option>
          <option value="draw">Draw (x{odds[1].odd})</option>
          <option value="away">Away (x{odds[2].odd})</option>
        </select>
      </div>

      <button
        onClick={handlePlaceBet}
        className="mb-4 w-full bg-blue-700 text-white font-semibold py-3 rounded-lg shadow-md hover:bg-blue-800 transition duration-300 ease-in-out"
      >
        Confirmar Apuesta
      </button>

      <button
        onClick={handlePay}
        className="w-full bg-blue-700 text-white font-semibold py-3 rounded-lg shadow-md hover:bg-blue-800 transition duration-300 ease-in-out"
      >
        Webpay
      </button>

      {message && (
        <div className="mt-4 p-3 bg-blue-50 border border-blue-200 text-blue-700 rounded-md shadow-sm">
          {message}
        </div>
      )}

      {/* <h4 className="text-lg font-bold text-gray-800 mt-8">
        Apuestas Anteriores
      </h4>
      <ul className="mt-4 space-y-2">
        {bets.length > 0 ? (
          bets
            .filter((bet) => bet.user_id !== null) // Filter out bets with user_id as null
            .map((bet) => (
              <li
                key={bet.id}
                className="py-3 px-4 bg-gray-50 border border-gray-200 rounded-lg shadow-sm"
              >
                <span className="font-semibold">
                  <span>
                    El user {bet.user_id} compro {} con potencial ganancia de
                    una apuesta
                  </span>
                </span>
              </li>
            ))
        ) : (
          <p className="text-gray-600">Aún no se han realizado apuestas.</p>
        )}
      </ul> */}
    </div>
  );
}

export default BetForm;
