import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_URL;

const Recommendations = () => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backendUser, setBackendUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (backendUser) {
      console.log("Fetching recommendations for user:", backendUser.id);
      const fetchRecommendations = async () => {
        try {
          const response = await axios.get(
            `${API_URL}/recommendations/${backendUser.id}`,
          );
          console.log("Recommendations:", response.data);
          setRecommendations(response.data);
        } catch (err) {
          setError("Error fetching recommendations: " + err.message);
        } finally {
          setLoading(false);
        }
      };
      fetchRecommendations();
    }
  }, [backendUser]);

  const fetchUserData = async () => {
    setLoading(true); // Iniciar la carga
    if (isAuthenticated && user) {
      try {
        // Obtener el token JWT de Auth0
        const token = await getAccessTokenSilently();

        // Hacer la solicitud al backend para obtener los datos del usuario
        const response = await axios.get(
          `${API_URL}/getInfo/userByEmail/${user.email}`,
          { headers: { Authorization: `Bearer ${token}` } },
        ); // Obtener el usuario por email

        // Almacenar los datos del usuario obtenidos del backend
        setBackendUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Finalizar la carga
      }
    }
  };

  const handleBetClick = (fixtureId, odds) => {
    navigate(`/betting`, { state: { fixtureId, odds } }); // Navigate to /betting with bet form
  };

  useEffect(() => {
    fetchUserData();
  }, [isAuthenticated, user]);

  if (loading) return <p>Loading recommendations...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-center text-white">
        Recommended Matches
      </h1>
      {recommendations.length ? (
        recommendations.map((recommendation) => (
          <div
            key={recommendation.fixture.id}
            className="flex flex-col border p-4 rounded-lg shadow-md bg-gray-100 bg-opacity-80 mb-4"
          >
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-4 ">
                <img
                  src={recommendation.fixture.teams.home.logo}
                  alt={recommendation.fixture.teams.home.name}
                  className="w-10 h-10"
                />
                <span>{recommendation.fixture.teams.home.name}</span>
              </div>
              <span>vs</span>
              <div className="flex items-center gap-4">
                <img
                  src={recommendation.fixture.teams.away.logo}
                  alt={recommendation.fixture.teams.away.name}
                  className="w-10 h-10"
                />
                <span>{recommendation.fixture.teams.away.name}</span>
              </div>
            </div>
            <div className="mb-2">
              <strong>Date:</strong>{" "}
              {new Date(recommendation.fixture.date).toLocaleString()}
            </div>
            <div className="mb-2">
              <strong>League:</strong>{" "}
              <img
                src={recommendation.fixture.league.logo}
                alt={recommendation.fixture.league.name}
                className="w-5 h-5 inline"
              />{" "}
              {recommendation.fixture.league.name} (
              {recommendation.fixture.league.round})
            </div>
            <div className="mb-2">
              <strong>Status:</strong> {recommendation.fixture.status.long}
            </div>
            <div className="mb-2">
              <strong>Referee:</strong>{" "}
              {recommendation.fixture.referee || "TBD"}
            </div>
            <div className="mb-2">
              <strong>Score:</strong> {recommendation.score || "TBD"}
            </div>
            <div className="mb-2">
              <strong>Updated:</strong> {recommendation.updatedAt || "TBD"}
            </div>
            {recommendation.fixture.odds &&
              recommendation.fixture.odds[0].values.length > 0 && (
                <>
                  <div className="mb-2">
                    <strong>Odds:</strong>
                    <div className="flex justify-between space-x-4">
                      {recommendation.fixture.odds[0].values.map(
                        (odd, index) => (
                          <div
                            key={index}
                            className="flex-1 border p-2 rounded-lg text-center"
                          >
                            <div className="text-lg font-bold">{odd.value}</div>
                            <div>{odd.odd}</div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                  <div className=" flex justify-center items-center mb-1">
                    <div className=" text-sm bg-gray-300 text-gray-800 p-2 rounded-lg">
                      <strong>Bets Available: </strong>
                      {recommendation.fixture.available_bets}
                    </div>
                  </div>
                  {recommendation.fixture.status.long === "Not Started" &&
                  recommendation.fixture.available_bets > 0 ? (
                    <button
                      onClick={() =>
                        handleBetClick(
                          recommendation.fixture.id,
                          recommendation.fixture.odds[0].values,
                        )
                      }
                      className="w-full bg-blue-700 hover:bg-blue-800 text-white py-2 rounded-lg mt-4"
                    >
                      Place Bet
                    </button>
                  ) : (
                    <div className="mt-4 bg-red-100 border border-red-500 text-red-500 p-4 rounded-lg text-center">
                      Betting not available for this match
                    </div>
                  )}
                </>
              )}
          </div>
        ))
      ) : (
        <p>No recommendations available.</p>
      )}
    </div>
  );
};

export default Recommendations;
