import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_URL;

function FixtureList() {
  const { getAccessTokenSilently } = useAuth0();
  const [fixtures, setFixtures] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    home: "",
    away: "",
    country: "",
    date: "",
  });
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const fetchFixtures = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      console.log("Token obtained:", token);
      const response = await axios.get(`${API_URL}/getInfo/fixtures`, {
        params: {
          page,
          ...filters,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFixtures(response.data.rows);
      setTotalPages(Math.ceil(response.data.count / 25));
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFixtures();
  }, [page, filters]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page on new search
  };

  const handleBetClick = (fixtureId, odds) => {
    navigate(`/betting`, { state: { fixtureId, odds } }); // Navigate to /betting with bet form
  };

  return (
    <div className="container mx-auto p-4">
      <form className="grid grid-cols-2 gap-2 mb-4" onSubmit={handleSearch}>
        <input
          type="text"
          name="home"
          placeholder="Home Team"
          className="border p-2 rounded placeholder-gray-800 text-gray-800 bg-gray-100 bg-opacity-75"
          value={filters.home}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="away"
          placeholder="Away Team"
          className="border p-2 rounded placeholder-gray-800 text-gray-800 bg-gray-100 bg-opacity-75"
          value={filters.away}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="country"
          placeholder="Country (e.g., Spain)"
          className="border p-2 rounded placeholder-gray-800 text-gray-800 bg-gray-100 bg-opacity-75"
          value={filters.country}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="date"
          className="border p-2 rounded placeholder-gray-800 text-gray-800 bg-gray-100 bg-opacity-75"
          value={filters.date}
          onChange={handleInputChange}
        />
        <button
          type="submit"
          className="col-span-2 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
        >
          Search
        </button>
      </form>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="flex flex-col gap-4">
          {fixtures.length ? (
            fixtures.map((fixture) => (
              <div
                key={fixture.id}
                className="flex flex-col border p-4 rounded-lg shadow-md bg-gray-100 bg-opacity-80"
              >
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-4 ">
                    <img
                      src={fixture.teams.home.logo}
                      alt={fixture.teams.home.name}
                      className="w-10 h-10"
                    />
                    <span>{fixture.teams.home.name}</span>
                  </div>
                  <span>vs</span>
                  <div className="flex items-center gap-4">
                    <img
                      src={fixture.teams.away.logo}
                      alt={fixture.teams.away.name}
                      className="w-10 h-10"
                    />
                    <span>{fixture.teams.away.name}</span>
                  </div>
                </div>
                <div className="mb-2">
                  <strong>Date:</strong>{" "}
                  {new Date(fixture.date).toLocaleString()}
                </div>
                <div className="mb-2">
                  <strong>League:</strong>{" "}
                  <img
                    src={fixture.league.logo}
                    alt={fixture.league.name}
                    className="w-5 h-5 inline"
                  />{" "}
                  {fixture.league.name} ({fixture.league.round})
                </div>
                <div className="mb-2">
                  <strong>Status:</strong> {fixture.status.long}
                </div>
                <div className="mb-2">
                  <strong>Referee:</strong> {fixture.referee || "TBD"}
                </div>
                {fixture.odds && fixture.odds[0].values.length > 0 && (
                  <>
                    <div className="mb-2">
                      <strong>Odds:</strong>
                      <div className="flex justify-between space-x-4">
                        {fixture.odds[0].values.map((odd, index) => (
                          <div
                            key={index}
                            className="flex-1 border p-2 rounded-lg text-center"
                          >
                            <div className="text-lg font-bold">{odd.value}</div>
                            <div>{odd.odd}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className=" flex justify-center items-center mb-1">
                      <div className=" text-sm bg-gray-300 text-gray-800 p-2 rounded-lg">
                        <strong>Bets Available: </strong>
                        {fixture.available_bets}
                      </div>
                    </div>
                    {fixture.status.long === "Not Started" &&
                    fixture.available_bets > 0 ? (
                      <button
                        onClick={() =>
                          handleBetClick(fixture.id, fixture.odds[0].values)
                        }
                        className="w-full bg-blue-700 hover:bg-blue-800 text-white py-2 rounded-lg mt-4"
                      >
                        Place Bet
                      </button>
                    ) : (
                      <div className="mt-4 bg-red-100 border border-red-500 text-red-500 p-4 rounded-lg text-center">
                        Betting not available for this match
                      </div>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <p>No fixtures found</p>
          )}
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 1 || loading}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <p>
          {page}/{totalPages}
        </p>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page >= totalPages || loading}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default FixtureList;
