import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/404";
import Register from "./pages/Register";
import FixturesList from "./pages/FixturesList";
import HacerApuesta from "./pages/HacerApuesta";
import WalletPage from "./pages/Wallet";
import Callback from "./components/Callback";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import Recommendation from "./pages/Recommendation";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/404", element: <NotFound /> },
    { path: "/register", element: <Register /> },
    { path: "/fixtures", element: <FixturesList /> },
    { path: "/betting", element: <HacerApuesta /> },
    { path: "/wallet", element: <WalletPage /> },
    { path: "/callback", element: <Callback /> },
    { path: "/paymentConfirmation", element: <PaymentConfirmation /> },
    { path: "/recommendations", element: <Recommendation /> },
  ]);

  return element;
};

export default ProjectRoutes;
