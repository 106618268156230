import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Home() {
  const { isAuthenticated, user } = useAuth0();

  return (
    <div className="flex-1 mt-24 mb-32 2xl:mx-44 xl:mx-40 lg:mx-32 sm:mx-4 text-center relative font-lexend">
      <div className="2xl:mx-56 lg:mx-28 sm:mx-12 space-y-5 text-gray-100">
        <h1 className="2xl:text-7xl lg:text-6xl sm:text-5xl space-y-5 font-bold drop-shadow-2xl shadow-black text-white">
          Compra tus bonos de los partidos que quieras
        </h1>
        {isAuthenticated ? (
          <div className="text-gray-300">
            <p>Estás autenticado como {user.name}</p>
          </div>
        ) : (
          <div className="text-gray-300">
            No estás autenticado
            {console.log("API URL: ", process.env.REACT_APP_API_URL)}
          </div>
        )}
      </div>
    </div>
  );
}
